import React, { useContext, useEffect } from 'react';
import NormalPage from '../../../../components/layout/NormalPage';
import LearningTabs from '../../../jebs/learning/component/LearningTabs';
import TextWinds from '../../../../components/common/TextWinds';
// import SortingButton from '../../../jebs/learning/component/SortingButton ';
import StudentHomeworkList from './StudentHomeworkList';
import { ToDoStatusEnum } from '../../../../enum';
import instance from '../../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../../constants/types';
import { myLearningTodoType } from '../../../../constants/types/student-type';
import { useParams } from 'react-router-dom';
import { StudentClassDetailContext } from '../StudentClassDetailContext';

const StudentHomeworkPage = () => {
  const { classId } = useParams();
  const [status, setStatus] = React.useState<ToDoStatusEnum | ''>('');
  const [count, setCount] = React.useState<{
    total: number;
    completed: number;
  }>({ total: 0, completed: 0 });
  const { getClassDetail, classDetail } = useContext(StudentClassDetailContext);
  const [isBasicClass, setIsBasicClass] = React.useState(false);

  useEffect(() => {
    if (classId) {
      getClassDetail(classId);
    }
  }, [classId]);

  // classDetail이 변경될 때 isBasicClass 상태 업데이트
  useEffect(() => {
    if (classDetail) {
      const isBasic = classDetail.course.name.includes('JEMM Basic Class');
      setIsBasicClass(isBasic);
    }
  }, [classDetail]);

  const headData = isBasicClass
    ? [
        {
          label: '전체',
          value: '',
        },
        {
          label: '진행 중',
          value: 'IN_PROGRESS',
        },
        {
          label: '완료',
          value: 'COMPLETED',
        },
        {
          label: 'AI 분석 중',
          value: 'PROCESSING_RESULT',
        },
      ]
    : [
        {
          label: '전체',
          value: '',
        },
        {
          label: '진행 중',
          value: 'IN_PROGRESS',
        },
        {
          label: '완료',
          value: 'COMPLETED',
        },
      ];

  const statusLabel = headData.find((item) => item.value === status)?.label;

  useEffect(() => {
    getHomework();
  }, []);

  // const handleSortChange = (sortState: string) => {
  //   // Handle the sorting state change here
  //   setSortingState(sortState);
  // };
  const setStatusHandler = (status: string) => {
    switch (status) {
      case 'IN_PROGRESS':
        setStatus(ToDoStatusEnum.IN_PROGRESS);
        break;
      case 'COMPLETED':
        setStatus(ToDoStatusEnum.COMPLETED);
        break;
      case 'NOT_STARTED':
        setStatus(ToDoStatusEnum.NOT_STARTED);
        break;
      case 'CLOSED':
        setStatus(ToDoStatusEnum.CLOSED);
        break;
      case 'PROCESSING_RESULT':
        setStatus(ToDoStatusEnum.PROCESSING_RESULT);
        break;
      default:
        setStatus('');
        break;
    }
  };

  const getHomework = async () => {
    const params = {
      page: 1,
      pageSize: 10,
    };
    try {
      const result = await instance.get<
        ApiResponse<{
          todos: myLearningTodoType[];
          completedCount: number;
          totalCount: number;
        }>
      >('/v1/student/class/' + classId + '/todo', { params });

      if (result.data.statusCode === 200) {
        setCount({
          total: result.data.data.totalCount,
          completed: result.data.data.completedCount,
        });
        if (result.data.data.todos[0]?.type === 'NEW_RECITATION') {
          setIsBasicClass(true);
        }
      } else {
        console.error(result.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <NormalPage xlBgColor="gray1" bgColor="gray1" paddingY={0} paddingX={0}>
        <LearningTabs
          data={headData}
          onChange={(tabValue) => setStatusHandler(tabValue)}
        >
          <div className="flex justify-between">
            <div className="flex gap-[6px] pl-5 pt-4 xl:pl-0 xl:pt-0">
              <TextWinds type="title_h4">{statusLabel}</TextWinds>
              <TextWinds type="content" color="gray6">
                ({count.total}개 중 {count.total - count.completed}개 미완료)
              </TextWinds>
            </div>
            {/* <SortingButton onSortChange={handleSortChange} /> */}
          </div>
          <div className=" w-full  mx-auto px-[20px] pt-0 pb-4 xl:px-[2px]">
            <StudentHomeworkList status={status} />
          </div>
        </LearningTabs>
      </NormalPage>
    </>
  );
};

export default StudentHomeworkPage;
