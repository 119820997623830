import { StudentHomeworkItemProps } from '../StudentHomeworkItem';
import { useParams } from 'react-router-dom';
import { openContentsView } from '../../../../../api/utils/util-func';
import React from 'react';
import { myLearningTodoType } from '../../../../../constants/types/student-type';
import { ToDoStatusEnum } from '../../../../../enum';
import { CookieTypeUserInfo, createCookie } from '../../../../../util/cookie/';
import dayjs from 'dayjs';
import {
  CookieKey,
  CookieTypeNewRecitation,
} from '../../../../../util/cookie/';
import { patchTodoStatus } from '../../../../../api/utils/api/studentApi';
import createNewWindow from '../../../../../controllers/createNewWindow';
export const useHomeworkItem = ({
  data,
  setTodoView,
}: StudentHomeworkItemProps) => {
  const { classId } = useParams();

  const [todo, setTodo] = React.useState<myLearningTodoType>(data);
  const checkStartDay = dayjs(todo.fromDate).isAfter(dayjs());
  const { setCookie } = createCookie<CookieTypeNewRecitation>(
    CookieKey.NEW_RECITATION
  );
  const { getCookie } = createCookie<CookieTypeUserInfo>(CookieKey.USER_INFO);
  const startHandler = () => {
    // 오늘 날짜와 시작일을 비교해서, 시작일 이전이면 경고창 띄우기
    if (checkStartDay) {
      alert('아직 시작일이 되지 않았습니다.');
      return;
    }

    if (todo.name.includes('Actual Test') || todo.name.includes('Daily Test')) {
      window.open(todo.link, 'TestView', 'location=no, menubar=no,');
    } else {
      // 뉴인증제의 경우에 쿠키저장
      if (todo.type === 'NEW_RECITATION') {
        const { studentId, uid } = getCookie();
        setCookie(
          {
            student: {
              id: studentId.toString(),
              uid: uid,
            },
            teacher: {
              id: '',
              uid: '',
              classId: classId,
            },
            todo: {
              id: todo.id,
              type: todo.type,
            },
            accessToken: localStorage.getItem('accessToken') || '',
          },
          1 / 24
        );
        createNewWindow(todo.link, 1440, 1440 / 1080);
      } else {
        const dataToSend = {
          type: 'todoView',
          contents: {
            url: todo.link,
          },
        };
        openContentsView(dataToSend, setTodoView);
      }
    }

    // 인증제만 마감처리 하던 코드
    // if (todo.type === 'RECITATION') {
    //   patchTodoStatus('CLOSED');
    //   setTodo((prev) => ({ ...prev, status: ToDoStatusEnum.CLOSED }));
    // } else {
    // patchTodoStatus('IN_PROGRESS');
    // setTodo((prev) => ({ ...prev, status: ToDoStatusEnum.IN_PROGRESS }));
    // }

    if (todo.status === 'COMPLETED') {
      return;
    }

    patchTodoStatus(classId, todo.id, 'IN_PROGRESS');
    setTodo((prev) => ({ ...prev, status: ToDoStatusEnum.IN_PROGRESS }));
  };

  const getButtonLabel = (status: ToDoStatusEnum) => {
    if (checkStartDay) {
      return '할 일 대기';
    }
    switch (status) {
      case 'IN_PROGRESS':
        return '진행 중';
      case 'COMPLETED':
        return data.name.includes('Test') ? '결과 보기' : '완료';
      case 'CLOSED':
        return '마감';
      case 'PROCESSING_RESULT':
        return 'AI 분석 중';
      default:
        return '할 일 시작';
    }
  };

  /**
   *
   * WMW 파랑
   * JS1 빨강
   * JS2 초록
   * HF 파랑
   * HJ 빨강
   * JEBSON 파랑
   * RECITATION 빨강
   * @param type
   * @returns
   */
  const getSubTypeColor = (type: string) => {
    switch (type) {
      case 'RECITATION':
      case 'NEW_RECITATION':
      case 'JS1':
      case 'HJ':
        return 'red-500';
      case 'JS2':
        return 'green-500';
      default:
        return 'skyblue';
    }
  };

  return {
    startHandler,
    getButtonLabel,
    getSubTypeColor,
    checkStartDay,
    todo,
  };
};
