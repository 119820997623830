import React from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import { myLearningTodoType } from '../../../../constants/types/student-type';
import HFbutton from '../../../../components/common/input/HFbutton';
import dayjs from 'dayjs';
import { getSubTypeName } from '../../../../api/utils/util-func';
import { useHomeworkItem } from './hooks/useHomeworkItem';

export interface StudentHomeworkItemProps {
  data: myLearningTodoType;
  setTodoView: (value: React.SetStateAction<Window>) => void;
  lastItemRef?: (node: HTMLDivElement) => void;
}
const StudentHomeworkItem = ({
  data,
  setTodoView,
  lastItemRef,
}: StudentHomeworkItemProps) => {
  const { startHandler, getButtonLabel, getSubTypeColor, checkStartDay, todo } =
    useHomeworkItem({
      data,
      setTodoView,
      lastItemRef,
    });

  return (
    <div
      className="w-full bg-white rounded-lg shadow-card p-5 flex justify-between items-center"
      ref={lastItemRef}
    >
      <div>
        <Chip
          color={getSubTypeColor(todo.type)}
          text={getSubTypeName(todo.type)}
        />
        <div>
          <TextWinds type={'title_h3'} children={todo.name} className="mt-2" />
        </div>
        <div>
          <TextWinds type={'caption1'} className="mt-2">
            {todo.status === 'CLOSED' || todo.status === 'COMPLETED' ? (
              'ㅤ'
            ) : (
              <>
                {`${dayjs(todo.fromDate).format('YY-MM-DD')} 부터 
            ${dayjs(todo.toDate).format('YY-MM-DD')}까지 (${
                  todo.remainDays
                }일 남음)`}
              </>
            )}
          </TextWinds>
        </div>
      </div>
      <div className="flex items-center">
        {/* 뉴인증제이고 status가 closed,completed 경우 버튼을 숨기고 합격 불합격 표시 */}
        {todo.type === 'NEW_RECITATION' &&
        (todo.status === 'CLOSED' || todo.status === 'COMPLETED') ? (
          // 합격 불합격 표시 ( 합격 초록, 불합격 빨강)
          <div
            className={`w-20 text-right sm:mr-3 ${
              todo.passDate ? 'text-green-400' : 'text-red5'
            }`}
          >
            {todo.passDate ? '합격' : '불합격'}
          </div>
        ) : todo.status === 'PROCESSING_RESULT' ? (
          // status가 PROCESSING_RESULT인 경우 버튼을 숨기고 'AI 분석 중' 텍스트 회색으로 표시
          <div className="w-20 text-right sm:mr-3 text-gray-700">
            AI 분석 중
          </div>
        ) : (
          <HFbutton
            onClick={startHandler}
            height={40}
            disabled={
              todo.status === 'CLOSED' ||
              (todo.status === 'COMPLETED' && !todo.name.includes('Test')) ||
              checkStartDay
            }
          >
            {getButtonLabel(todo.status)}
          </HFbutton>
        )}
      </div>
    </div>
  );
};

interface ChipProps {
  color: 'tangerine5' | 'skyblue' | string;
  text: string;
}
const Chip = ({ color, text }: ChipProps) => {
  return (
    <div
      className={`text-${color} border border-${color} rounded-md text-center w-fit p-1 text-sm `}
    >
      {text}
    </div>
  );
};

export default StudentHomeworkItem;
