import React, { useEffect, useState } from 'react';
import { assignedLmsTodosByTodo } from '../../../constants/types/lms-type';
import { Badge, Button, Table } from 'antd';
import { getTodoStateDetailByTodo } from '../../../api/utils/lms/lmsApi';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { createCookie } from '../../../util/cookie/createCookie';
import {
  CookieKey,
  CookieTypeNewRecitation,
  CookieTypeUserInfo,
} from '../../../util/cookie';
import createNewWindow from '../../../controllers/createNewWindow';

type DetailType = ColumnsType<assignedLmsTodosByTodo>;
const columsRECITATION: DetailType = [
  {
    title: '응시결과',
    render: (_, row) => getPassBadge(row),
    align: 'center',
  },
  {
    title: '진행현황',
    render: (_, row) => getProgressStateBadge(row.progress.progressState),
    align: 'center',
  },
];

const columsBasic: DetailType = [
  {
    title: '진행률',
    render: (_, row) => {
      const percent = row.progress.progress;
      return (typeof percent === 'number' ? percent : '0') + '%';
    },
    align: 'center',
  },
  {
    title: '상태',
    render: (_, row) => getProgressStateBadge(row.progress.progressState),
    align: 'center',
  },
];

const columns = (
  subType: string,
  contentsHandler: (url: string) => void,
  pagination: { current: number; pageSize: number },
  isTest?: boolean,
  status?: string,
  showResult?: (row: assignedLmsTodosByTodo) => void
): DetailType => {
  const rtn: DetailType = [
    {
      title: 'No.',
      render: (_, __, index) =>
        index + 1 + pagination.pageSize * (pagination.current - 1),
      align: 'center',
    },
    {
      title: '이름',
      render: (_, row) => row.student.name,
      align: 'center',
    },
    {
      title: '아이디',
      render: (_, row) => row.student.uid,
      align: 'center',
    },
  ];

  if (subType === 'recitation') {
    rtn.push(...columsRECITATION);
  } else if (subType === 'new_recitation') {
    rtn.push(...columsRECITATION);
    if (status === 'COMPLETED' || status === 'CLOSED') {
      rtn.push({
        title: '응시결과',
        render: (_, row) => (
          <Button onClick={() => showResult(row)}>결과보기</Button>
        ),
        align: 'center',
      });
    }
  } else {
    rtn.push(...columsBasic);

    if (isTest) {
      rtn.push({
        title: '응시결과',
        render: (_, row) => (
          <Button onClick={() => contentsHandler(row.progress.link)}>
            결과
          </Button>
        ),
        align: 'center',
      });
    }
  }
  return rtn;
};

const getProgressStateBadge = (progressState: string) => {
  switch (progressState) {
    case 'NOT_STARTED':
      return <Badge status="error" text="진행 전" />;
      {
        /* <Badge status="error" text="진행 전" />; */
        /* <Badge status="processing" text="진행 중" />; */
        /* <Badge status="success" text="마감" />; */
        /* <Badge status="warning" text="진행 전" /> */
      }

    case 'IN_PROGRESS':
      return <Badge status="processing" text="진행 중" />;
    case 'CLOSED':
    case 'COMPLETED':
      return <Badge status="success" text="마감" />;
    case 'PROCESSING_RESULT':
      return <Badge status="warning" text="AI 분석 중" />;
    default:
      return;
  }
};

const getPassBadge = (data: assignedLmsTodosByTodo) => {
  let status: 'default' | 'success' | 'error' | 'warning' | 'processing';
  let date = '';

  if (data.progress.passDate) {
    status = 'success';
    date = '합격 : ' + dayjs(data.progress.passDate).format('YYYY-MM-DD');
  } else if (data.progress.failDate) {
    status = 'error';
    date = '불합격 : ' + dayjs(data.progress.failDate).format('YYYY-MM-DD');
  } else if (data.progress.testDate) {
    status = 'warning';
    date = '연습 : ' + dayjs(data.progress.testDate).format('YYYY-MM-DD');
  } else {
    status = 'default';
    date = '미응시';
  }

  return <Badge status={status} text={date} />;
};

interface TodoExpandedRowByTodoProps {
  classId: string;
  params: {
    toDoType: string;
    toDoStatus: string;
    category: 'BY_TODO';
    search?: {
      searchOption: string;
      searchValue: string;
    };
  };
  txId: string;
  subType: string;
  contentsHandler: (url: string) => void;
  isTest?: boolean;
}
const TodoExpandedRowByTodo = ({
  classId,
  params,
  txId,
  subType,
  contentsHandler,
  isTest,
}: TodoExpandedRowByTodoProps) => {
  const [data, setData] = useState<assignedLmsTodosByTodo[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  useEffect(() => {
    setLoading(true);
    getTodoStateDetailByTodo(
      classId,
      params.toDoType,
      params.toDoStatus,
      txId,
      {
        searchOption: params.search?.searchOption,
        searchValue: params.search?.searchValue,
      }
    ).then((data) => {
      setData(data.data);
      setLoading(false);
    });
  }, [
    classId,
    params.toDoType,
    params.toDoStatus,
    params.search?.searchOption,
    params.search?.searchValue,
    txId,
  ]);

  const { setCookie } = createCookie<CookieTypeNewRecitation>(
    CookieKey.NEW_RECITATION
  );
  const { getCookie } = createCookie<CookieTypeUserInfo>(CookieKey.USER_INFO);

  const showResult = (row: assignedLmsTodosByTodo) => {
    const { id, uid } = getCookie();
    // if (row.progress.passDate) {
    setCookie(
      {
        student: {
          id: row.student.user_id,
          uid: row.student.uid,
        },
        teacher: {
          id: id,
          uid: uid,
          classId: classId,
        },
        todo: {
          id: row.progress.id,
          type: row.progress.type,
        },
        accessToken: localStorage.getItem('accessToken'),
      },
      1 / 24
    );
    createNewWindow(row.progress.link, 1440, 1440 / 1080);
    // }
  };

  return (
    <Table
      dataSource={data}
      columns={columns(
        subType,
        contentsHandler,
        pagination,
        isTest,
        params.toDoStatus,
        showResult
      )}
      size="small"
      rowKey={(row) => row.progress.id}
      pagination={
        data.length > 10
          ? {
              current: pagination.current,
              pageSize: pagination.pageSize,
              onChange: (page, pageSize) =>
                setPagination({ current: page, pageSize }),
              style: {
                marginTop: 30,
                marginBottom: 0,
                justifyContent: 'center',
              },
            }
          : false
      }
      loading={loading}
    />
  );
};

export default TodoExpandedRowByTodo;
