import dayjs from 'dayjs';
import { carouselItem } from './types';

export const IMAGE_LIST: carouselItem[] = [
  {
    img: 'images/home/banner/bannerD-25.01.31.jpg',
    mobile: 'images/home/banner/bannerM-25.01.31.jpg',
    title: '리뉴얼 홈페이지 배너- 사도행전 B세트 사전할인가',
    link: '/shop/details/CoV_Dm3uYHgZR8x3',
    date: {
      start: dayjs('2025-02-01'),
      end: dayjs('2025-02-25'),
    },
  },
  {
    img: 'images/home/banner/bannerD-25.01.23.jpg',
    mobile: 'images/home/banner/bannerM-25.01.23.jpg',
    title: '리뉴얼 홈페이지 배너- 2월 홈스쿨 이벤트',
    link: '/courses/homeschool/details/DAvFDd_N0nbFgISL',
    date: {
      start: dayjs('2025-02-01'),
      end: dayjs('2025-02-28'),
    },
  },
  {
    img: 'images/home/banner/bannerD-25.02.05.jpg',
    mobile: 'images/home/banner/bannerM-25.02.05.jpg',
    title: '리뉴얼 홈페이지 배너- 2월 갓딜위크',
    link: 'https://cafe.naver.com/jcebs/26351',
    type: '_blank',
    date: {
      start: dayjs('2025-02-06'),
      end: dayjs('2025-02-17'),
    },
  },
  {
    img: 'images/home/banner/bannerD-25.01.20-2.jpg',
    mobile: 'images/home/banner/bannerM-25.01.20-2.jpg',
    title: '리뉴얼 홈페이지 배너- 미남재형 영상',
    link: 'https://youtu.be/oTgp_34O-eg',
    type: '_blank',
    date: {
      start: dayjs('2025-01-20'),
      end: dayjs('2025-03-16'),
    },
  },
  {
    img: 'images/home/banner/bannerD-24.09.12-2.jpg',
    mobile: 'images/home/banner/bannerM-24.09.12-2.jpg',
    title: '리뉴얼 홈페이지 배너- 김PD가간다-광명교회',
    link: 'https://youtu.be/Cqt7cDnZVNc?si=i9ontV0V1sNtOM3V',
    type: '_blank',
  },
  {
    img: 'images/home/banner/bannerD-24.12.17.jpg',
    mobile: 'images/home/banner/bannerM-24.12.17.jpg',
    title: '리뉴얼 홈페이지 배너- A.D 영어학습법',
    link: '/shop/details/KwSCZEaDWmHeUzku',
    date: {
      start: dayjs('2024-12-18'),
      end: dayjs('2025-02-28'),
    },
  },
  {
    img: 'images/home/banner/bannerD-24.08.19.jpg',
    mobile: 'images/home/banner/bannerM-24.08.19.jpg',
    title: '리뉴얼 홈페이지 배너- 영어로 그림전도 출간',
    link: '/shop/details/U9NHpfT92l_A4zTE',
  },
];
