import Cookies from 'js-cookie';

export enum CookieKey {
  NEW_RECITATION = 'NEW_RECITATION',
  USER_INFO = 'USER_INFO',
}
type sameSite = 'Lax' | 'None' | 'Strict';
export const createCookie = <GData>(key: CookieKey) => {
  const config = {
    domain: 'jebs.kr',
    path: '/',
    secure: true,
    sameSite: 'None' as sameSite,
  };

  return {
    setCookie: (value: GData, days?: number) => {
      Cookies.set(key, JSON.stringify(value), {
        ...config,
        expires: days,
      });
    },
    getCookie: (): GData => JSON.parse(Cookies.get(key)),
    removeCookie: () => {
      Cookies.remove(key, {
        ...config,
      });
    },
  };
};
